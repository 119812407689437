import * as bootstrap from 'bootstrap';

import jQuery from 'jquery';
window.$ = jQuery;

(function($){
    const activeSticky = $('#active-sticky'),
        winD = $(window),
        sections = ['#home', '#about-us', '#process', '#faqs', '#contact-us'];

    winD.on('scroll',function() {
        var scroll = $(window).scrollTop(),
        isSticky = activeSticky;
        if (scroll < 1) {
            isSticky.removeClass("is-sticky");
        }
        else{
            isSticky.addClass("is-sticky");
        }
    });

    const handleClick = (evt) => {
        evt.preventDefault();

        const href = evt.target.getAttribute('href');

        if(sections.includes(href)) {
            if(window.location.href.substr(window.location.href.lastIndexOf("/")+1) === 'login') {
                window.location.href = `/${href}`;
            }else {
                let element = document.querySelector(evt.target.getAttribute('href'));

                element?.scrollIntoView({
                    behavior: 'smooth'
                });
            }
        } else {
            window.location.href = href;
        }

        document.querySelector('#hamburger-menu').click();
    }

    const navItems = document.querySelectorAll('#navbarToggler .nav-item');
    navItems.forEach(item => {
        item.addEventListener('click', handleClick);
    })


})(window.$);
